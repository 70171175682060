.description-1 {
  margin: 0 auto;
  font-size: 16px;
  padding: 2rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .colony-image {
    width: 20vw;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .colony-image {
    width: 15vw;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .colony-image {
    width: 10vw;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .colony-image {
    width: 8vw;
  }
}

h2 {
  font-size: 1.5rem;
  text-align: center;
}

.encode-text {
  background-color: #dcebb7;
}

.decode-text {
  background-color: #c2d5e9;
}

.encode-text,
.decode-text {
  height: 100%;
  padding: 1.5rem;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row {
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.parameter-box {
    width: 20vw;
    height: 81vh;
    background-color: #DCDCDC;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}
.initial-config {
    width: 11.5vw;
    height: 22vh;
    border: solid 1px black;
    margin: 3vh 5vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.encode {
    width: 11.5vw;
    height: 22vh;
    border: solid 1px black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3vh 0;
}
.circle-1 {
    width: 11vw;
    height: 21vh;
    background-color: black;
    border-radius: 50%;
}
.circle-2 {
    width: 11vw;
    height: 21vh;
    background-color: black;
    border-radius: 50%;
}

  
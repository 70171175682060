.box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;
  padding-top: 100%;
  z-index: 0;
  position: relative;
}
.video-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;
  padding-top: 100%;
  z-index: 0;
  position: relative;
}
.circle-3 {
  background-color: black;
  border-radius: 50%;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.small-circle {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 48%;
  left: 48%;
}

.video {
  z-index: 5;
  position: relative;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
}

.arrow-button:hover {
  cursor: pointer;
  color: #5b7585;
}

.slideshow-image:hover {
  cursor: pointer;
}

.pause-overlay {
  z-index: 6;
  border: none;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: white;
  background-color: #ffffff50;
  padding: 2px;
}

.pause-overlay:hover {
  z-index: 6;
  cursor: pointer;
  opacity: 1;
}

.pause-overlay i.icon::before {
  border-radius: 100%;
  background-color: black !important;
  -webkit-box-shadow: inset 0px 0px 0px 0.1em white;
  -moz-box-shadow: inset 0px 0px 0px 0.1em white;
  box-shadow: inset 0px 0px 0px 0.1em white;
}

/*
styling the custom inline loading icon on the decoding button
to have the same appearance as the default loading icon on the
encoding button
*/

.ui.active.inverted.inline.loader.button-loader {
  vertical-align: top;
}

.ui.active.inverted.inline.loader.button-loader::before {
  border: 0.2em solid rgba(0, 0, 0, 0.15);
  width: 1.28571429em;
  height: 1.28571429em;
  margin: none;
  padding: none;
  display: inline;
}
.ui.active.inverted.inline.loader.button-loader::after {
  width: 1.28571429em;
  height: 1.28571429em;
  margin: none;
  padding: none;
  display: inline;
}
.ui.active.inverted.inline.loader.button-loader {
  width: 1.28571429em;
  height: 1.28571429em;
  margin: none;
  padding: none;
  display: inline;
}

/*
.input-1 {
  width: 18vw;
  height: 34vh;
  border: solid #a0a0a0 3px;
  color: #a0a0a0;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 65px;
}
.input-2 {
  width: 18vw;
  height: 34vh;
  border: solid #a0a0a0 3px;
  color: #a0a0a0;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 26px;
}
*/

::placeholder {
  color: #3c3c3c;
  font-size: 16px;
  text-align: center;
}

h1 {
  color: #3c47e6;
  font-size: clamp(1.4rem, 0.4rem + 1.5vw, 2rem);
  font-family: Helvetica Neue;
  margin-bottom: 0;
}

/*
textarea::-webkit-input-placeholder {
    text-align: center;
}

textarea:-moz-placeholder { /* Firefox 18-
    text-align: center;
}

textarea::-moz-placeholder {  /* Firefox 19+
    text-align: center;
}

textarea:-ms-input-placeholder {
    text-align: center;
}

textarea::placeholder {
    text-align: center;
}
*/

.subheader-1 {
  color: #4d72de;
}
hr {
  width: 78%;
  margin-bottom: 5vh;
}
.subheader-2 {
  color: #4d72de;
  margin-left: 11vw;
}
.description-2 {
  font-family: Calibri;
  width: 50%;
  margin: 0 auto;
  font-size: 17px;
}
